<template>
  <section v-if="betList.length > 0">
    <ul class="bettype">
      <li class="typea">{{ betList[0].leagueNameKO || betList[0].leagueName}}</li>
      <li class="typeb">{{ betList[0].homeTeamKO || betList[0].homeTeam}} vs {{ betList[0].awayTeamKO || betList[0].awayTeam}}</li>
      <li class="typec">{{ betList[0].lineTypeName }}</li>
    </ul>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <colgroup>
            <col style="width: 14.28%;"/>
            <col style="width: 14.28%;"/>
            <col style="width: 14.28%;"/>
            <col style="width: 14.28%;"/>
            <col style="width: 14.28%;"/>
            <col style="width: 14.28%;"/>
            <col style="width: 14.28%;"/>
          </colgroup>
          <table-head :headInfo="headInfo" />
          <tbody>
            <template v-for="item in betList" :key="item.betIdx">
              <tr>
                <td>{{ item.branchName }}</td>
                <td> - </td>
                <td>{{ item.leagueNameKO || item.leagueName }}</td>
                <td>{{ item.homeTeamKO || item.homeTeam }}</td>
                <td>{{ item.awayTeamKO || item.awayTeam }}</td>
                <td>{{ numberWithCommas(item.betAmt) }} / {{ numberWithCommas(item.gain) }}</td>
                <td>{{ replaceDateT(item.betDate) }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="betList.length !== 0" @goPage="goPage" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import DateSelectors from '@/components/common/DateSelector_day'
import TableHead from '@/components/main/table/Head.vue'
import UiCheck from '@/components/ui/UiCheckSet'
import { GAME_INFO_LIST, GAME_CODE_NAME, SPORT_BRANCH_LIST, STATUS_LIST } from '@/libs/constants'
import { numberWithCommas, replaceDateT, thousand } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import { mapState } from 'vuex'
import { sportEventDetailList, sportEventList } from '@/api/bet'

export default {
  name: 'EventDetailList',
  components: {
    SearchFilter,
    DateSelector,
    DateSelectors,
    TableHead,
    UiCheck,
    Pagination
  },
  data: function () {
    return {
      active: false,
      tableData: {},
      headInfo: {
        fstColumn: false,
        dataList: ['sporta', 'gametime', 'sportb', 'sportc', 'sportg', 'betwin', 'sportj']
      },
      order: '',
      reqData: {
        siteId: '',
        lineTypeID: '',
        newMasterEventID: '',
        newEventID: ''
      },
      pageInfo: {},
      betList: [],
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      branchIDList: []
    }
  },
  methods: {
    numberWithCommas,
    thousand () {
      return thousand
    },
    replaceDateT,
    async getSportEventDetailList (pageNum) {
      this.emitter.emit('Loading', true)

      if (pageNum) {
        this.reqData.page = pageNum
      } else {
        this.reqData.page = 1
      }

      const data = this.reqData
      if (this.order !== '') {
        const tempOrder = this.order.split(' ')
        const orderColumn = tempOrder[0]
        const orderType = tempOrder[1]

        data.orderColumn = orderColumn
        data.orderType = orderType
      } else {
        data.orderColumn = ''
        data.orderType = ''
      }

      console.log('list params : ', data)
      sportEventDetailList(data).then(res => {
        console.log(res)
        this.betList = []
        if (res.resultCode === '0') {
          const list = res.data.list

          console.log('betInfo List: ', res)

          this.betList = list
        }
        this.emitter.emit('Loading', false)
      })
    }
  },
  computed: {
    SPORT_BRANCH_LIST () {
      return SPORT_BRANCH_LIST
    },
    STATUS_LIST () {
      return STATUS_LIST
    },
    ...mapState([
      'siteOption'
    ]),
    siteId () {
      return this.siteOption.siteId
    }
  },
  async created () {
    this.branchIDList.push({
      value: null,
      text: '전체'
    })
    for (const key in SPORT_BRANCH_LIST) {
      this.branchIDList.push({
        value: key,
        text: SPORT_BRANCH_LIST[key]
      })
    }
    console.log(this.$route.query)
    const query = this.$route.query

    this.reqData = {
      siteId: query.siteId,
      lineTypeID: Number(query.lineTypeID),
      newMasterEventID: query.newMasterEventID,
      newEventID: query.newEventID,
      oldYn: query.oldYn
    }

    await this.getSportEventDetailList()
  }
}
</script>

<style scoped>
.bettype {display: flex;align-items: center;margin: 30px 35px 0;border: solid 1px #e1e0e0;border-top: 5px solid #82a6a4;border-left: 0;background: #fff;width: fit-content;}
.bettype li {border-left: solid 1px #e1e0e0;padding: 5px 10px;}
.typea {color: #0006ff;}
.typeb {color: #0087ff;}
.typec {color: #00b6ff;}
.typed {color: #9a6fff;}
.typef {color: #f16fff;}
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.b-sports {display: flex;align-items: center;justify-content: center;border-radius: 3px;padding: 3px;cursor: pointer;width: 90px;margin: 0 auto;}
</style>
